export default {
  home: {
    hero: {
      title1: "Devenez Ambassadeur chez",
      title2: "projetsolaire.",
      subtitle: "Participez à la Révolution Solaire et Gagnez en Même Temps!",
      button: "Commencer",
      "learn-more": "En savoir plus",
    },
    why: {
      whyup: "Pourquoi ?",
      title: "Pourquoi devenir un ambassadeur de l'énergie solaire ?",
      reason1: {
        title: "Impact Environnemental :",
        description:
          "Contribuez à la lutte contre le changement climatique en promouvant une source d'énergie propre et renouvelable.",
      },
      reason2: {
        title: "Revenus Supplémentaires :",
        description:
          "Gagnez de l'argent en recommandant nos services et en aidant les propriétaires à passer à l'énergie solaire.",
      },
      reason3: {
        title: "Formation et Soutien :",
        description:
          "Bénéficiez d'une formation complète et d'un soutien continu pour réussir en tant qu'ambassadeur.",
      },
      reason4: {
        title: "Flexibilité :",
        description:
          "Travailler à votre rythme, où vous le souhaitez, en fonction de vos horaires et de votre style de vie.",
      },
      caption:
        '"J’ai déjà parrainé deux amis et j’ai pu les accompagner dans leur parcours vers l’autoconsommation. Leurs installations se sont déroulées rapidement, ils sont ravis. Tout le monde est gagnant !"',
    },
    how: {
      title: "Comment se lancer ?",
      description: "Voici les étapes des ambassadeurs de chez projet solaire.",
      step1: {
        title: "Candidature",
        description: "Déposez votre candidature en quelques clics.",
      },
      step2: {
        title: "Parrainage",
        description:
          "Commencez à parrainer vos contacts souhaitant passer au photovoltaïque.",
      },
      step3: {
        title: "Récompense",
        description:
          "Percevez 500 euros pour chaque devis que vous ferez signer.",
      },
    },
    tools: {
      title: "Nos outils",
      description:
        "Nous vous donnons accès à des outils pour vous faciliter la tâche.",
      tool1: {
        title: "Formation :",
        description:
          "Bénéficiez d'une formation complète pour pouvoir vous lancer dans l’aventure solaire en toute sérénité ! ",
      },
      tool2: {
        title: "Accompagnement :",
        description:
          "Vous recevrez du matériel marketing pour vous aider à atteindre vos objectifs. ",
      },
    },
    faq: {
      title: "Questions Fréquentes (FAQ) :",
      q1: {
        question: "Comment fonctionne le programme d'ambassadeur ?",
        respond:
          "Notre programme d'ambassadeur vous récompense chaque fois que vous recommandez un client qui signe un devis avec nous. Plus vous recommandez, plus vous gagnez.",
      },
      q2: {
        question: "Y a-t-il des coûts associés à devenir ambassadeur ?",
        respond:
          "Non, l'inscription en tant qu'ambassadeur est entièrement gratuite.",
      },

      q3: {
        question:
          "Ai-je besoin de connaissances préalables en énergie solaire ?",
        respond:
          "Non, nous fournissons une formation complète pour vous préparer à promouvoir nos services.",
      },
    },
    cta: {
      title: "Devenez Ambassadeur chez projetsolaire",
      description:
        "Rejoignez notre équipe d'ambassadeurs passionnés de l'autoconsommation électrique et transformez votre passion en profit. En tant qu'ambassadeur, vous aurez l'opportunité de promouvoir l'indépendance électrique et d'aider les gens à économiser sur leurs factures d'énergie tout en gagnant de l'argent. Soyez un leader dans le mouvement vers un avenir énergétique plus intelligent et plus économique.",
      button: "Rejoindre l'aventure",
    },
    banner: {
      paragraph: "Devenez Ambassadeur chez projetsolaire !",
      button: "Rejoindre l'aventure",
    },
  },
  about: {
    hero: {
      title: "À propos ",
      description: "Apprenez-en plus sur le processus de parrainage.",
      paragraph1:
        "Participez à notre programme d’ambassadeur et aidez à transformer le paysage de l'énergie photovoltaïque avec projetsolaire. Le programme d’ambassadeur projetsolaire, c’est une communauté dédiée qui souhaitent aider les français à passer au solaire. Nous avons besoin de vous pour changer le paysage du photovoltaïque et faire connaître cette notre plateforme communautaire. Encouragez vos proches à découvrir projetsolaire et à passer à l'énergie solaire.",
      paragraph2:
        "Vous participerez ainsi activement à l’expansion du parc photovoltaïque français ainsi qu'à la transition énergétique vers un modèle de production et de consommation d'énergie renouvelable éthique et durable, à travers un programme simple et transparent.",
      paragraph3:
        "Engagez-vous et aidez-nous à révolutionner le monde du photovoltaïque ! Devenez notre porte-parole pour un avenir d'énergie propre et durable.Si vous êtes une personne majeure résidant en France, vous pouvez rejoindre notre programme et participer à la révolution solaire ! Le principe est simple : accompagnez un prospect dans son projet solaire, grâce à la formation spéciale et au matériel marketing que nous mettons à votre disposition.",
      paragraph4:
        "À la signature, vous recevrez une compensation. Sans aucune limite de temps ou de nombre de signatures ; vous pouvez travailler à votre rythme et selon vos disponibilités.",
    },
    process: {
      title: "Processus",
      description:
        "Vous souhaitez intégrer notre programme de parrainage ? Rien de plus simple.",

      step1: {
        title: "Référez quelqu'un",
        description:
          "Recommandez une personne à considérer pour une étude technico-financière",
      },

      step2: {
        title: "Présentez l'étude solaire",
        description:
          "Partagez-lui et expliquez les résultats et détails de l'étude solaire. Vous disposerez d’une formation préalable pour vous accompagner.",
      },

      step3: {
        title: "Partagez les devis",
        description:
          "Obtenez des estimations et des devis pour le projet solaire de votre prospect et aidez-le à les étudier.",
      },

      step4: {
        title: "Gagnez de l'argent",
        description:
          "Recevez une compensation quand votre filleul signe le devis final.",
      },
    },
    how: {
      title: "Comment se lancer ?",
      description: "Voici les étapes des ambassadeurs de chez projet solaire.",
      step1: {
        title: "Candidature",
        description: "Déposez votre candidature en quelques clics.",
      },
      step2: {
        title: "Parrainage",
        description:
          "Commencez à parrainer vos contacts souhaitant passer au photovoltaïque.",
      },
      step3: {
        title: "Récompense",
        description:
          "Percevez 500 euros pour chaque devis que vous ferez signer.",
      },
    },
  },
  "sign-in": {
    title:
      "Bienvenu ambassadeur, aidez nous à accroître le photovoltaïque en France !",
  },
}
