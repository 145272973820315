export default {
  consumption: "Consommation d'électricité",
  production: "Production d'électricité solaire",
  local_consumption: "Autoconsommation d'électricité solaire",
  consumption_from_grid: "Consommation du réseau",
  offpeak_consumption: "Consommation d'électricité en heures creuses",
  peak_consumption: "Consommation d'électricité en heures pleines",
  cumulative_net_cash_flows: "Rentabilité du système photovoltaïque sur 25 ans",
  "electricity-bill-without-solar": "Facture électrique sans le solaire",
  "electricity-bill-with-solar": "Facture électrique avec le solaire",
  "electricity-bill-savings": "Économies sur la facture électrique",
}
