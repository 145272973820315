import { unionState } from "@common/js/store/helpers"

const state = () => ({
  items: [],
})

const actions = {
  async create({ commit }, params) {
    const response = await axios.post(`/lead`, params)
    commit("storeItem", response.data)
    return response.data
  },
}

const mutations = {
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
}

const getters = {
  getById: (state) => (id) => state.items.find((b) => b.id === id),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}