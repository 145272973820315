import { createRouter, createWebHistory } from "vue-router"
import { useHead } from "@unhead/vue"
import i18n from "./i18n"
import routes from "./routes"

const history = createWebHistory("/")

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: "smooth" })
        }, 500)
      })
    } else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: "smooth" })
        }, 500)
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: history,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { title, description } = to.meta
  const lang = to.params.lang || i18n.global.locale.value
  if (lang) {
    i18n.global.locale.value = lang
  }
  if (title && description) {
    useHead({
      title: i18n.global.t(`meta-tags.${title}`),
      link: [
        {
          rel: "canonical",
          href: `${import.meta.env.VITE_BASE_URL}${to.fullPath}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: i18n.global.t(`meta-tags.${description}`),
        },
        {
          name: "og:title",
          content: i18n.global.t(`meta-tags.${title}`),
        },
        {
          name: "og:description",
          content: i18n.global.t(`meta-tags.${description}`),
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:image",
          content: import.meta.env.VITE_OG_IMAGES_LANDING,
        },
      ],
    })
  }
  next()
})

export default router
