const actions = {
  async subscribeHouseholderNewsletter({}, { params }) {
    await axios.post(`/newsletter/householders/subscribe`, params)
  },

  async unsubscribeHouseholderNewsletter({}, { params }) {
    await axios.post(`/newsletter/householders/unsubscribe`, params)
  },

  async subscribeInstallerNewsletter({}, { params }) {
    await axios.post(`/newsletter/installers/subscribe`, params)
  },

  async unsubscribeInstallerNewsletter({}, { params }) {
    await axios.post(`/newsletter/installers/unsubscribe`, params)
  },
}

export default {
  namespaced: true,
  actions,
}
