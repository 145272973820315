<script>
export default {
  data() {
    return {
      status: null,
    }
  },
  computed: {
    statusText() {
      return this.status || "No cookie set"
    },
  },

  methods: {
    cookieStatus(status) {
      console.log("status: " + status)
      this.status = status
    },
    cookieClickedAccept() {
      this.$gtm.enable(true)
      this.status = "accept"
    },
    cookieClickedDecline() {
      this.$gtm.enable(false)
      this.status = "decline"
    },
    cookieClickedPostpone() {
      console.log("here in postpone")
      this.status = "postpone"
    },
    cookieRemovedCookie() {
      this.$gtm.enable(false)
      this.status = null
      this.$refs.myPanel1.init()
    },
    removeCookie() {
      console.log("Cookie removed")
      this.$refs.myPanel1.removeCookie()
    },
  },
}
</script>

<template>
  <div>
    <vue-cookie-accept-decline
      :debug="false"
      :disable-decline="false"
      :show-postpone-button="false"
      element-id="gdpr"
      position="bottom"
      transition-name="slideFromBottom"
      type="bar"
      style="background: #ffff"
      class="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-y-4 gap-x-8 p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-24"
    >
      <!-- Optional -->
      <template #message>
        <div
          class="flex items-center"
          style="padding-top: 0px; padding-bottom: 0px"
        >
          <div style="font-size: 60px">
            <img
              src="https://images.ctfassets.net/1b6f0wuf6ave/5OhZIArSBUdBQWX9qN1mPr/502ccf2dcd9c2c69c185a3c2b0d4615c/Flag_of_Europe.svg.png"
              class="h-14 mr-10 hidden sm:block"
            />
          </div>
          <p class="max-w-4xl text-sm leading-6 text-black">
            {{ $t("common.cookie-gdpr.cookie-text-1") }}<br />
            {{ $t("common.cookie-gdpr.cookie-text-2") }}
            <a
              href="/help/guides/installers/legal/declaration-de-confidentialit"
              target="_blank"
              class="font-semibold text-black"
            >
              {{ $t("common.cookie-gdpr.cookie-link") }} </a
            >.
          </p>
        </div>
      </template>

      <!-- Optional -->
      <template #acceptContent>
        {{ $t("common.cookie-gdpr.accept") }}
      </template>

      <!-- Optional -->
      <template #declineContent>
        {{ $t("common.cookie-gdpr.decline") }}
      </template>
    </vue-cookie-accept-decline>
  </div>
</template>

<style lang="scss">
.cookie {
  // Bar style
  &__bar {
    &--bottom {
    }

    &--top {
    }

    &__postpone-button {
    }

    &__content {
    }

    &__buttons {
      &__button {
        &--accept {
          cursor: pointer;
          display: inline-block;
          padding: 0.5rem 1rem;
          background: black !important;
          color: white !important;
          border-radius: 12px !important;
        }

        &--accept:hover {
          background: rgb(24, 24, 24) !important;
          color: rgb(231, 231, 231) !important;
        }

        &--decline {
          cursor: pointer;
          display: inline-block;
          padding: 0.5rem 1rem;
          background: transparent !important;
          color: black !important;
          border-radius: 12px !important;
        }

        &--decline:hover {
          background: white !important;
          color: rgb(31, 31, 31) !important;
        }
      }
    }
  }

  // Floating style
  &__floating {
    &--bottom-left {
    }

    &--bottom-right {
    }

    &--top-right {
    }

    &--top-left {
    }

    &__postpone-button {
    }

    &__content {
    }

    &__buttons {
      &__button {
        &--accept {
        }

        &--decline {
        }
      }
    }
  }
}
</style>
