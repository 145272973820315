export default {
  hero: {
    title:
      "Découvrez pourquoi le meilleur moment pour passer au solaire c'est maintenant",
    subtitle:
      "Commencez à vous familiariser au solaire et découvrez les témoignages d'autres.",
  },
  "installation-steps": {
    title: "Comment passer à l'énergie solaire en France ?",
    subtitle:
      "Vous trouverez ci-dessous les tutoriels expliquant comment passer à l'énergie solaire en France. Toutes ces étapes peuvent être effectuées en ligne et vous permettent de trouver un installateur de confiance à un prix compétitif.",
  },
  featured: {
    title: "Guide complet du solaire en six épisodes",
    subtitle:
      "Renseignements à jour avec sources rédigées à partir des questions de milliers de concitoyens.",
  },
  latest: {
    title: "Nouveaux articles",
    subtitle:
      "Restez à jour sur les derniers articles provenant de notre équipe éditoriale indépendante.",
    by: "Écrit par ",
  },

  series: {
    topics: "Sujets",
    "bread-crumb": {
      resources: "ressources",
      topics: "sujets",
    },
    "topics-series": {
      supplySideNewsletter: "Le Point Réglementaire",
      stories: "Témoignages",
      "why-go-solar": "Guide d'autonomie solaire",
      "how-to-go-solar": "Comment démarrer ?",
    },
  },
  popular: {
    "popular-on": "Populaire sur Projetsolaire",
    by: "Par ",
  },
  "find-installer": {
    title: "Trouver un installateur :",
    description:
      "Trouvez vos installateur RGE proche de chez vous dès aujourd'hui",
    "learn-more": "En savoir plus",
    "get-quote": "Obtenir un devis",
  },
}
