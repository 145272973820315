import { unionState } from "@common/js/store/helpers"
import qs from "qs"

const state = () => ({
  items: [],
})

const actions = {
  async fetchAll({ commit }, params) {
    const response = await axios.get(`/products/search`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    commit("storeItems", response.data.items)
    return response.data
  },

  async fetch({ commit }, id) {
    const response = await axios.get(`/products/${id}`)
    commit("storeItem", response.data)
    return response.data
  },
}

const mutations = {
  storeItems(state, items) {
    state.items = unionState(state.items, items)
  },
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
}

const getters = {
  getById: (state) => (id) => state.items.find((p) => p.id === id),
  getByCategory: (state) => (category) =>
    state.items.filter((p) => p.category === category),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
