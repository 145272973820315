import { unionState } from "@common/js/store/helpers"

const state = () => ({
  items: [],
})

const actions = {
  fetch({ commit }, { id }) {
    return axios.get(`/installer-directory/${id}`).then((response) => {
      commit("storeItem", response.data)
    })
  },
  search({ commit }, params) {
    return axios.get(`/installer-directory/search`).then((response) => {
      commit("storeItems", response.data.items)
    })
  },
}

const mutations = {
  storeItems(state, items) {
    state.items = unionState(state.items, items)
  },
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
