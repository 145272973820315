const state = () => ({
  form: null,
})

const actions = {
  async fetch({ commit }, { token }) {
    const response = await axios.get(
      `/dynamic-forms/enedis-consumption-permission?token=${token}`
    )
    commit("storeForm", response.data)
    return response.data
  },

  async submit({}, token) {
    await axios.post(
      `/dynamic-forms/enedis-consumption-permission?token=${token}`
    )
  },
}

const mutations = {
  storeForm(state, form) {
    state.form = form
  },
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
