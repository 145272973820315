import { createI18n } from "vue-i18n"
import translations from "@/js/translations"

const i18n = createI18n({
  legacy: false,
  locale: "fr",
  fallbackLocale: "fr",
  formatFallbackMessages: true,
  messages: translations,
})

export default i18n
