const state = () => ({
  form: null,
})

const actions = {
  async fetch({ commit }, { token }) {
    const response = await axios.get(`/dynamic-forms/users?token=${token}`)
    commit("storeForm", response.data)
    return response.data
  },

  async submit({}, { params }) {
    await axios.post(`/dynamic-forms/users`, params)
  },
}

const mutations = {
  storeForm(state, form) {
    state.form = form
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
