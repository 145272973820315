<script>
import Snackbar from "@/js/components/Snackbar.vue"
import CookieConsentDialog from "@/js/components/CookieConsentDialog.vue"
import { formatFullName } from "@common/js/helpers"
import { mapState } from "vuex"

export default {
  components: {
    CookieConsentDialog,
    Snackbar,
  },
  data() {
    return {
      intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,
      intercomEnabled: import.meta.env.VITE_INTERCOM_ENABLED === "true",
    }
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
      userType: (state) => state.type,
    }),
  },
  watch: {
    user(user) {
      window.Intercom("update", { email: user ? user.email : null })
    },
  },

  mounted() {
    if (this.intercomEnabled) this.bootIntercom()
  },

  methods: {
    bootIntercom() {
      window.Intercom("boot", {
        app_id: this.intercomAppId,
        user_id: this.user ? this.user.id : null,
        name: this.user ? this.getIntercomName(this.user) : null,
        email: this.user ? this.user.email : null,
      })
    },

    getIntercomName(user) {
      if (this.userType === "installer") return user.companyName
      else return this.formatFullName(user)
    },
    formatFullName,
  },
}
</script>

<template>
  <div>
    <Snackbar />
    <router-view></router-view>
    <CookieConsentDialog />
  </div>
</template>
