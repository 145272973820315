export default {
  navigation: {
    installers: "Installers",
    blog: "Blog",
    help: "Help",
    "about-us": "About us",
    "sign-in": "Sign in",
    "sign-up": "Sign up",
  },

  footer: {
    "products-for-installers": "Products for installers",
    "find-clients": "Find clients",
    administration: "Administration",
    "digital-sales-tools": "Digital sales tools",
    resources: "Resources",
    documentation: "Documentation",
    guide: "Guide",
    blog: "Blog",
    "support-centre": "Support Center",
    partners: "Partners",
    enterprise: "Company",
    home: "Home",
    "about-us": "About us",
    "company-values": "Company Values",
    pricing: "Pricing",
    newsletter: "Subscribe",
    "subscribe-newsletter":
      "Get the latest news and articles to your inbox every month",
    "your-email": "Your email",
    copyright: "2023 © projetsolaire.com. All rights reserved.",
    "terms-and-conditions": "Terms",
    "privacy-policy": "Privacy Policy",
    "contact-us": "Contact",
    press: "Presse",
    technology: "Technologie",
  },

  "reset-password": {
    title: "Recover your password",
    subtitle:
      "Entrez l'adresse email que vous avez utilisée lors de votre inscription, et nous vous enverrons un email automatique pour vous réinitialiser votre mot de passe.",
    "check-your-inbox": "vérifiez votre boîte de réception",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
    success: {
      title: "vérifiez votre boîte de réception",
      description: "",
    },
    callback: {
      title: "réinitialiser votre mot de passe",
      "description-1":
        "« Pour protéger vos informations, il est nécessaire de choisir et d’utiliser des mots de passe robustes, c’est-à-dire difficiles à retrouver à l’aide d’outils automatisés et à deviner par une tierce personne »",
      "description-2": "www.interieur.gouv.fr",
      anssi: "l’Agence Nationale de la Sécurité des Systèmes d’Information",
      password: "Password",
      "confirm-password": "Confirm password",
      "reset-your-pass-word": "Reset password",
    },
    "forgot-password": {
      title: "Forgot your password ?",
      description:
        "Enter the email address you used when you registered your account, and we will send you a secure password reset email.",
      "select-type": "You are ...",
      email: "Email",
      "reset-your-pass-word": "Reset password",
    },
  },

  "sign-in": {
    title: "Welcome Back! ",
    "type-select": "You are ...",
    "having-trouble": "Having trouble signing in?",
    "keep-me-signed-in": "Keep me signed in",
    "dont-have-an-account": "Don't you have an account? ",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
  },

  "sign-up": {
    title:
      "Welcome. Start your <span class='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-400'>solar</span> journey today.",
    tc: " By creating an account, you agree to the <a class='underline' href='/help/guides/installers/legal/declaration-de-confidentialite'>terms & conditions</a>, and our <a class='underline' href='/help/guides/installers/legal/declaration-de-confidentialite'>privacy policy</a>.",
    "already-registered": "Already using Projetsolaire?",
    "company-name": "Votre raison sociale",
    "enter-your-company-name": "Entrez votre raison sociale",
    "password-confirm": "Confirmez votre mot de passe",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
    success: {
      title:
        "Bienvenue ! Veuillez consulter votre courrier électronique avant d'accéder au service élance.",
      description:
        'Veuillez consulter votre boîte mail afin de confirmer la création de votre compte. Si vous ne voyez pas l\'email de confirmation tout de suite, veuillez vérifier votre dossier "spam" ou "courrier indésirable" et marquez-le "non-spam". Le message de confirmation s\'y retrouve parfois par erreur. Si vous avez une question n\'hésitez pas à nous contacter par courrier électronique.',
    },
    callback: {
      success: {
        title: "Inscription réussie",
        description: "L'email de votre compte a été validé avec succès",
      },
      invites: {
        installer: {
          title: "Vous êtes invité pour rejoindre votre équipe solaire",
          description:
            "Veuillez confirmer l’invitation pour créer votre compte à vous et obtenir l’accès à l’espace professionnel en ligne.",
        },
        user: {
          title: "",
          description: "",
        },
        lead: {
          title: "",
          description: "",
        },
      },
    },
  },

  home: {
    hero: {
      "find-an-installer": "Find an installer",
      "i-am-installer": "I'm an installer",
      title:
        "Helping France go <span class='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-400'>solar</span>",
      subtitle:
        "The easiest way to go solar, from discovering your solar potential to installation day.",
      "learn-more": "Learn more",
      "discover-potential": "Discover your solar potential",
      "watch-video": "Watch the full video (30s)",
    },

    "how-to": {
      title: "How to go solar?",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.",
    },

    "why-us": {
      title: "Pourquoi passer au solaire avec nous ?",
      description:
        "Nous vous encadrons la réalisation de votre projet solaire. Notre plateforme communautaire repose sur des principes simples : transparence des offres des maitres d'œuvres, parcours à votre rythme, sans engagements. Le service est gratuit pour les particuliers.",
      features: {
        model: {
          title: "Get a in-depth customised solar model",
          description:
            "We take your real historic consumption data from the grid and combine that with an estimate of your solar production using Lidar to produce a 25 year financial model of your own solar project.",
        },
        installers: {
          title: "Get quotes from trusted installers",
          description:
            "We have a network of installers ready to quote on your project. Benefit from a wide range of options and achieve a better price",
        },
        administration: {
          title: "Take care of your administration",
          description:
            "Let us take care of all your administration as part of the solar installation journey. Track its progress online or on your mobile.",
        },
        support: {
          title: "Support all the way until installation day",
          description:
            "Any questions during your installation journey? We are here for you, we have a dedicated staff ready to answer any questions you may have",
        },
      },
    },
    stats: {
      "our-track-record": "Our track record",
      title: "Weve helped homes all across France go solar",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      "stats-value": {
        "3-quotes-guarantee": {
          title: "Entreprises par appel d'offres, moyenne",
          value: "4 propositions",
        },
        "installers-in-network": {
          title: "Installateurs photovoltaïques chez nous",
          value: "100 +",
        },
        "cost-reduction": {
          title: "Réduction sur montant du devis, moyenne",
          value: "40 %",
        },
        "aided-solar-projects": {
          title: "Projets solaires rendus possible",
          value: "3 000 +",
        },
      },
    },

    stories: {
      title: "Discover others solar journey",
      description:
        "Meet our community of households who have gone solar all across france Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum ",
      button: "Discover all stories",
    },

    community: {
      title: "Join our community of solar projects",
      description:
        "We have helped people go solar all across France. Start your solar journey today and join the rest of France on the national solar mission",
    },
    mission: {
      title: "Our mission",
      paragraph1:
        "Our objective is to facilitate, secure and then democratize at scale all the stages of the photovoltaic installation.",
      paragraph2: "Make it easier to go solar",
      paragraph3: "End scams",
      paragraph4: "Massively increase the number of solar installations",
      paragraph5:
        "Projetsolaire.com is therefore the solar project of a family, a local business, a community … it is their solar project, it is her solar project. This is finally your solar project too.",
    },

    cta: {
      title: "Want to discover more?",
      subtitle: "Please book a call on our current site Maison Elance.",
      benefit: {
        benefit1: "Competitive salaries",
        benefit2: "Flexible work hours",
        benefit3: "30 days of paid vacation",
        benefit4: "Annual team retreats",
        benefit5: "Benefits for you and your family",
        benefit6: "A great work environment",
      },
      button: "Discover more",
    },
  },

  installer: {
    services: {
      hero: {
        title: "Helping you grow your installation business",
        paragraph:
          "Cupidatat minim id magna ipsum sint dolor qui. Sunt sit in quis cupidatat mollit aute velit. Et labore commodo nulla aliqua proident mollit ullamco exercitation tempor. Sint aliqua anim nulla sunt mollit id pariatur in voluptate cillum. Eu voluptate tempor esse minim amet fugiat veniam occaecat aliqua.",
        "button-1": "Demander une démo",
        "button-2": "Démarrer maintenant",
      },
      "value-proposition": {
        "everything-you-need": "Everything you need",
        title: "The platform to manage your solar projects",
        paragraph:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.",
        "key-value": {
          "team-management": {
            title: "Team management",
            description: "bla bla bla",
          },
          "digital-presentations": {
            title: "Digital presentations",
            description: "bla bla bla...",
          },
          administration: {
            title: "Administration",
            description: "Bla bla bla !",
          },
          "quotes-management": {
            title: "Quote management",
            description: "Bla bla bla ?",
          },
          "hardware-management": {
            title: "Hardware management",
            description: "bla bla bla ?!",
          },
          "calendar-management": {
            title: "Calendar management",
            description: "bla bla bla.",
          },
        },
      },
      stats: {
        "why-us": "Why us ?",
        title: "Get support to grow your installation business",
        paragraph:
          "Rhoncus sagittis risus arcu erat lectus bibendum. Ut in adipiscing quis in viverra tristique sem. Ornare feugiat viverra eleifend fusce orci in quis amet. Sit in et vitae tortor, massa. Dapibus laoreet amet lacus nibh integer quis. Eu vulputate diam sit tellus quis at.",
        "number-stats": {
          "founded-in": {
            stat: "2020",
            emphasis: "Founded",
            rest: "during first confinement.",
          },
          "installer-on-platform": {
            stat: "50+",
            emphasis: "Local businesses",
            rest: "with us.",
          },
          servicing: {
            stat: "100+",
            emphasis: "Administration services",
            rest: "per month.",
          },
          "projects-in-france": {
            stat: "1000+",
            emphasis: "Solar projects",
            rest: "made possible.",
          },
        },
      },
      testimonials: {},
      "service-block": {
        title: "The solutions that we propose",
        description: "Explore how to grow your business while saving time.",
        services: {
          "meet-new-customers": "Meet new customers",
          "automate-administration": "Automate the administrative process",
          "explore-digital": "Explore the digital",
        },
      },
      commitments: {
        "why-us": "L'informatique au service du photovoltaïque",
        title: "Nos engagements",
        paragraph:
          "La France est le seul pays en Europe qui a installé moins de kW de panneaux solaires cette année que l’année dernière. Les délais dus aux démarches administratives se comptent en plusieurs mois. Le progrès technique fait qu’une démarche de rupture est nécessaire. Nous vous aidons l'artisanat de France à gagner du temps pour mieux croître.",
        "key-values": {
          value1: {
            title:
              "Faciliter aux professionnels techniques l’exercice de leur cœur de métier.",
            description: "",
          },
          value2: {
            title:
              "Libérer enfin la filière photovoltaïque de l’éco-délinquance.",
            description: "",
          },
          value3: {
            title:
              "Proposer les services numériques de pointe aux artisans pour réduire leurs délais ainsi que coûts de commercialisation.",
            description: "",
          },
          value4: {
            title:
              "Renforcer l’indépendance de la filière par rapport aux grands groupes de sous-traitance et des plateformes contraignantes",
            description: ".",
          },
          value5: {
            title:
              "Valoriser les entreprises locales et le savoir-faire de proximité.",
            description: "",
          },
          value6: {
            title:
              "Réduire la fracture numérique qui se creuse dans notre pays.",
            description: "",
          },
        },
      },

      cta: {
        "start-using": "Start using our platform today.",
        paragraph:
          "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
        button: "Get started",
        "learn-more": "Learn more",
      },
    },
    "find-new-clients": {
      hero: {
        "discover-new-clients": {
          title: "Découvrir l'installateur de nouveaux clients",
          paragraph:
            "Vous voulez vous impliquer dans d'autres projets ? Nous avons des clients qui attendent de passer au solaire dans toute la France.",
          button: "Get started",
          "learn-more": "Learn more",
        },
      },
      process: {
        step1: {
          title: "Get verified",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step2: {
          title: "Start browsing projects",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step3: {
          title: "Quote",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step4: {
          title: "Install",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
      },
      features: {
        "search-local-area": {
          title: "Trouvez des chantiers en ligne",
          paragraph:
            "L'utilisateur choisi le cahier de charges qui correspondra au mieux à l'orientation énergétique du projet solaire. L’utilisateur est accompagné dans son choix grâce à notre étude technico-financière de son installation photovoltaïque. Celle-ci inclut l’évaluation au kWh/m² de l’ensoleillement ainsi que l'analyse horaire de la courbe de charge sur site. L’utilisateur choisi son cahier de charges parmi plusieurs options.",
          button: "En savoir plus",
        },

        "use-your-hardware": {
          title: "Vous choisissez le matériel",
          paragraph:
            "Prenez connaissance du cahier des charges de l'utilisateur pour personaliser au mieux. Renseignez vos modèles de modules et d'onduleurs. La faisabilité technique est validée par vous-même lors de la visite technique obligatoire sur place.",
          button: "",
        },

        "submit-custom-quotes": {
          title: "Chiffrez votre prix",
          paragraph:
            "Fixez librement votre prix et votre acompte en soumettant votre proposition avec votre matériel, prestations et tarifs. Détaillez le profil public de votre établissement. Seule l’entreprise retenue par l’utilisateur à le droit de le contacter. Vous pouvez ainsi rencontrer l’utilisateur en personne dès la visite technique.",
          button: "",
        },

        pricing: {
          title: "Bénéficiez de chantiers clés en main",
          paragraph:
            "Faites-vous payer automatiquement par virement bancaire en ligne. Le service est facturé que quand vous êtes payé : si vous gagnez 0 € pour un mois donné, notre service vous sera gratuit pour ce mois-ci. Les frais de service incluts la réalisation des démarches administratives, financières et assurancielles.",
          button: "",
        },

        "manage-the-projects-you-won": {
          title: "Concentrez-vous sur l'essenteil",
          paragraph:
            "Bénéficiez d’une plateforme intégrée avec signatures électroniques, paiements sécurisés, planification de chantiers et d’autres fonctionnalités d’automatisation. Nos pages de paiement prêtes à l’emploi s'intègrent dans vos méthodes de facturation, pour une expérience de paiement améliorée. Suivez ainsi les agendas, planifiez les visites, et pilotez le chantier à distance via notre tableau de bord simplifié.",
          button: "",
        },
      },
      pricing: {
        title: "Simple no-tricks pricing",
        paragraph:
          "Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.",
        "on-card": {
          title: "Lifetime membership",
          paragraph:
            "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
          "what-included": "What's included",
          items: {
            item1: "Private forum access1",
            item2: "Private forum access2",
            item3: "Private forum access3",
          },
          "pay-win": "Winning pricing",
          price: "$349",
          usd: "USD",
          button: "Get access",
          "little-line":
            "Invoices and receipts available for easy company reimbursement",
        },
      },
      cta: {
        title: "Join our network of installers today",
        paragraph: "Join our network to see more.",
        "button-1": "Démarrer maintenant",
        "button-2": "Documentation",
      },

      // Not in the page anymore //
      contact: {
        "contact-hero": {
          title: "Contactez-nous",
          paragraph:
            "Vel nunc non ut montes, viverra tempor. Proin lectus nibh phasellus morbi non morbi. In elementum urna ut volutpat. Sagittis et vel et fermentum amet consequat.",
        },
        "contact-form": {},
      },
      // Not in the page anymore //
    },

    administration: {
      hero: {
        administration: {
          title: "Administration",
          paragraph:
            "Simplifiez votre processus administratif avec projetsolaire.",
          button: "Get started",
          "request-a-demo": "Request a demo",
        },
      },
      "value-propositions": {
        "why-us": "Why us?",
        title: "All in one platform",
        "paragraph-1":
          "Dotez chaque membre de votre équipe d'un puissant logiciel pour la réalisation et le suivi de vos chantiers. L'outil informatique réduit vos délais, et permet à tous d’y voir plus clair.",
        "paragraph-2":
          "Vos commerciaux peuvent facilement télécharger les photos et documents sur votre compte.",
        "paragraph-3":
          "Votre secrétariat peut rapidement lancer puis suivre le déroulement des démarches sur le même logiciel.",
        "key-values": {
          value1: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value2: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value3: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value4: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value5: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value6: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value7: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value8: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
        },
      },
      products: {
        title: "Our services",
        paragraph:
          "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
        "requests-for-urban-planning-authorizations": {
          title: "Demandes d'autorisations d’urbanisme",
          subtitle:
            "La réalisation de la démarche d’urbanisme pour votre système photovoltaïque auprès de l’institution locale compétente.",
          "paragraph-1":
            "Dématérialisation des dossiers de déclaration préalable (ou permis de construire) grâce à l’API du Géoportail et l’utilisation systématique de guichet unique.",
          "paragraph-2":
            "Obtention de non-opposition, en PLU ou RNU. Envoi puis suivi des dossiers, avec preuves de dépôt et avis de réception des lettres recommandées. Relances matinales des mairies par téléphone.",
          "paragraph-3":
            "Interlocution avec Bâtiments de France (ABF), parcs régionaux, et autres autorités jusqu’à l’attestation de non-opposition à la conformité.",
        },
        "request-for-enedis": {
          title: "Demande de Raccordement",
          subtitle:
            "La réalisation de toutes les démarches de raccordement de votre système photovoltaïque auprès du réseau électrique Enedis et d'un cocontractant.",
          "paragraph-1":
            "Obtention de PDR ou PTF, CRAE ou CAE pour production d'électricité solaire (ou augmentation de puissance). Obtention aussi de la simulation du prix du devis Enedis selon le barème de facturation.",
          "paragraph-2":
            "Envoi et relance  Relance et suivi de l’avancement des dossiers par messagerie Enedis.",
          "paragraph-3":
            "Inclus aussi l’obtention EDF OA pour votre client, ou bien la démarche avec un co-contractant auprès de tous les fournisseurs de batterie virtuelle (Urban Solar Energy, MyLight Systems, JPME).",
        },
        "requests-for-consuel": {
          title: "Visa Consuel",
          subtitle:
            "La réalisation de toutes les démarches auprès du Consuel pour votre système photovoltaïque. Obtention des attestations de conformité.",
          "paragraph-1":
            "La réalisation de la fiche de collecte, du SC 144 (A/B) et du schéma électrique unifilaire auprès du Consuel pour votre système photovoltaïque. Gestion et suivi du dossier jusqu’à l’obtention pour mise en service de l’Attestation de Conformité « Bleu » (n°15523*01). Création et Test de l’attestation. Prévention de l’inspection du Consuel et planification de la visite.",
          "paragraph-2":
            "Avertissement : service exclut l’achat des d’Attestations de Conformité (AC). Le service numérique n’est à ce jour pas connecté au compte Consuelpro.com",
        },
      },
      "new-pricing": {
        pricing: "Pricing",
        title: "Pricing plans for teams of all sizes",
        paragraph:
          "Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.",
        "producer-size": "Producer size",
        option: {
          "small-producer": "Small producer",
          "large-producer": "Large producer",
        },
        "buy-plan": "Buy plan",

        "price-card": {
          "requests-for-urban": {
            title: "Demandes d'autorisations d'Urbanisme",
            price: {
              small: "149€HT",
              big: "249€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de la démarche d’urbanisme pour votre système photovoltaïque auprès de l’institution locale compétente. Dématérialisation des demandes grâce à l’API du Géoportail et l’utilisation systématique de guichet unique. Obtention de non-opposition de déclaration préalable ou permis de construire, en PLU ou RNU. Envoi puis suivi des dossiers, avec preuves de dépôt et avis de réception des lettres recommandées. Interlocution avec Bâtiments de France (ABF), parcs régionaux, et autres autorités jusqu’à l’attestation de non-opposition à la conformité.",
          },
          "requests-for-enedis": {
            title: "Demandes de raccordement",
            price: {
              small: "99€HT",
              big: "699€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de toutes les démarches de raccordement de votre système photovoltaïque auprès du réseau électrique public, Enedis. Obtention de PDR ou PTF, CRAE ou CAE. Obtention de la simulation du prix du devis Enedis selon le barème de facturation. Relance et suivi de l’avancement des dossiers par messagerie Enedis. Inclus aussi l’obtention EDF OA pour votre client, ou bien la démarche avec un co-contractant auprès de tous les fournisseurs de batterie virtuelle (Urban Solar Energy, MyLight Systems, JPME)..",
          },
          "requests-for-consuel": {
            title: "Demandes de Visa Consuel",
            price: {
              small: "49€HT",
              big: "99€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de la fiche de collecte, du SC-144 (A/B/C) et du schéma électrique unifilaire auprès du Consuel pour votre système photovoltaïque. Gestion et suivi du dossier jusqu’à l’obtention pour mise en service de l’Attestation de Conformité « Bleu » (n°15523*01). Création et Test de l’attestation. Prévention de l’inspection du Consuel et planification de la visite.",
          },
          package: {
            title: "Package",
            price: {
              small: "249€HT",
              big: "999€HT",
            },
            features: {
              feat1: "Unlimited products",
              feat2: "Unlimited subscribers",
              feat3: "Advanced analytics",
            },
            description:
              "La réalisation de l’ensemble des démarches administratives pour particuliers – d’autorisations d’urbanisme, Enedis, Visa Consuel, EDF OA ou batterie virtuelle, jusqu’à la mise en service.",
          },
        },
      },
      cta: {
        title: "Externalise your administration today.",
        paragraph:
          "Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.",
        button: "Get started",
        "learn-more": "Learn more",
      },
    },
    "digital-sales-tools": {
      hero: {
        interested: "Interested ?",
        "request-a-demo": "Request a demo",
        title: "A better way to present to your client",
        paragraph:
          "Esse id magna consectetur fugiat non dolor in ad laboris magna laborum ea consequat. Nisi irure aliquip nisi adipisicing veniam voluptate id. In veniam incididunt ex veniam adipisicing sit.",
        button: "Get started",
        "learn-more": "Learn more",
        animation: {
          intro: {
            welcome: "Bienvenue",
          },
          production: {
            title: "Annual production",
            paragraph:
              "Votre potentielle production d'électricité solaire est déterminée en fonction du nombre et de la puissance (en kWc) de vos panneaux solaires,ainsi que de leurs rendements énergétiques (en kWh/kWc).",
          },
          consumption: {
            title: "Consommation annuel",
            paragraph:
              "Votre consommation annuelle d'électricité est déterminée en fonction de la quantité d'électricité que vous utilisez, mesurée en kilowattheures (kWh).Cela dépend de vos habitudes de consommation.",
          },
          system: {
            title: "Mon système solaire",
            paragraph:
              "Nous vous réalisons votre projet solaire. Voici votre bien actuel sans panneaux photovoltaïques.",
            value: {
              "technical-specs": {
                title: "Cahier de charges",
                value: "16 kWc",
              },
              type: {
                title: "Type",
                value: "Classique",
              },
              "cellular-photovoltaique": {
                title: "Cahier de charges",
                value: "Monocrystallins",
              },
              inverters: {
                title: "Onduleur",
                value: "Micro-ondeleurs",
              },
              "manufacturer-warranty": {
                title: "Guarantie de fabricant",
                value: "25 ans",
              },
            },
          },
        },
      },
      configurable: {
        styling: {
          title: "Pick you companies branding",
          paragraph:
            "Want to personalise your clients presentations to your brands colour scheme? No problem!",
        },
      },

      "value-proposition": {
        "everything-you-need": "Everything you need",
        title: "No server? No problem.",
        paragraph:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.",
        "key-value": {
          a: {
            title: "lidar",
            description: "bla bla bla",
          },
          b: {
            title: "hourly",
            description: "bla bla bla...",
          },
          c: {
            title: "your bill",
            description: "Bla bla bla !",
          },
        },
      },
      features: {
        a: {
          title: "Stay on top of customer support",
          paragraph:
            "Accédez à la courbe de charge réelle du propriétaire. Notre étude technico-financière sur 25 ans repose sur les mesures horaires (en kWh). L’historique de consommation est obtenu directement depuis le compteur Linky d'Enedis. Cette précision technique permet de calculer l'autoconsommation électrique heure par heure.",
          paragraph2: "projetsolaire :",
          paragraph3: "une entité référencée auprès d'Enedis",
          button: "Get started",
        },
        b: {
          title: "Better understand your customers",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        c: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        d: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        e: {
          title: "Better understand your customers",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        f: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
      },
      pricing: {
        title: "Simple pay as you go pricing",
        paragraph:
          "If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.",
        "on-card": {
          title: "Presentation",
          paragraph:
            "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
          "what-included": "What's included",
          features: {
            feat1: "Digital web link to share with client",
            feat2: "Up to 3x changes can be requested",
            feat3: "Team member access",
            feat4: "Customisable slides",
          },
          "pay-as-you-go": "Pay as you go",
          "price-big": "100 €",
          "price-small": "Starting at",
          "join-waiting-list": "Learn about our membership policy",
          button: "Get started",
          "free-sample": "Get a free sample",
        },
      },
      "help-center": {
        title: "Need help getting started ?",
        paragraph:
          "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
        button: "Read our docs",
        "request-a-demo": "Request a demo",
      },
    },

    discover: {
      systeme: {
        datasheet: {
          title: "Fiche technique",
          paragraph:
            "Vous trouverez ci-dessous les détails techniques du projet",
          systemepower: "Puissance du système",
          numberpanels: "Nombre de panneau",
          panelstype: "Type de panneaux",
          roofheight: "Hauteur du toit",
          roofstructure: "Structure du toit",
          roofmaterials: "Matériaux du toit",
          roofaccessibility: "Accessibilité du toit",
          panelpreference: "Préférence panneau",
          quotenumber: "Nombre de devis reçu",
          Typenetworkconnection: "Type de branchement",
          powernetworkkva: "Puissance de la Souscription du réseau en kVa",
        },
      },
    },

    "case-studies": {
      home: {
        header: {
          title: "Our customers stories",
          overview: "Overview",
          all: "All customer stories",
        },
        overview: {
          hero: {
            title:
              "La plateforme pour photovoltaïque qui soutient des entreprises locales partout en France",
            paragraph:
              "L'objectif de notre plateforme est de permettre aux installateurs photovoltaïques à forte croissance d'exploiter les technologies numériques de pointe de manière simple et collaborative. Notre solution informatique est co-développée avec nos clients pour qu'ils puissent gagner du temps pour faire plus. Nous opérons sur l'ensemble du territoire national et propulsons des milliers de projets solaires chaque année pour des entreprises de toutes tailles, des artisans de proximité aux leaders du solaire.",
          },

          testimonials: {
            heading: "Testimonials",
            title: "We have worked with hundreds of amazing installers",
          },

          cta: {
            title: "Envie d’essayer ?",
          },
        },
        all: {
          title:
            "L’outil informatique pour la filière photovoltaïque de France",
        },
      },
      entry: {
        breadcrumb: {
          installer: "Installers",
          "case-studies": "Case studies",
        },
        "case-study": "Case study",
        "learn-more":
          "Learn more about how we can help your installation business",
      },
    },
  },

  blog: {
    hero: {
      title: "Start learning solar",
      subtitle:
        "Discover how to go solar and read up on other peoples solar journeys.",
    },
    "installation-steps": {
      title: "How to go solar in France?",
      subtitle:
        "Below are the tutorials outlining how to go solar in France. All these steps can be completed online and get you a trusted installer with a competitive price.",
    },
    featured: {
      title: "Main articles",
      subtitle: "Browse through some of our most read articles.",
    },
    latest: {
      title: "New articles",
      subtitle:
        "Stay up to date on the latest articles from our independent editorial team.",
      by: "written by ",
    },
    series: {
      topics: "Topics",
      "topics-series": {
        stories: "Stories",
        "why-go-solar": "Why go solar",
        "how-to-go-solar": "How to go solar in France ?",
      },
    },
    popular: {
      "popular-on": "Popular on Projetsolaire",
      by: "By ",
    },
  },

  about: {
    "mission-statement":
      "<span class='opacity-50'>Our</span> mission <span class='opacity-50'>is</span><br /> to solve residential solar <br /> in France",
    intro: {
      title: "We enable solar projects to happen",
      description:
        "Our aim is to transform solar project installation, making it faster, cheaper, simpler... better!",
    },
    story: {
      title: "Our journey",
      "paragraph-1": ".",
      "paragraph-2": "",
      "paragraph-3": "",
      "paragraph-4": "",
      "paragraph-5": "",
      "paragraph-6":
        "Implantée en Provence, notre startup innovante compte trois cofondateurs-associés. À ce jour, nous n’avons jamais eu besoin de lever de l’argent ni de dépenser un seul euro en publicité. Au contraire, notre méthode c’est la satisfaction client et le bouche-à-oreille.",
      "paragraph-7": "",
      "paragraph-8": "",
    },
    stats: {
      title: "Acteur de l’indépendance énergétique de France.",
      description:
        "Notre plateforme communautaire réunit tous les porteurs de projets solaires avec les professionnels du photovoltaïque ; ensemble avec tous les acteurs de la transition énergétique - bureaux d’études, fournisseurs, financiers, municipalités, services publics.",
      "flagship-projects":
        "Nous nous sommes élancé dans le solaire lors du premier confinement.",
      "installers-on-our-platform":
        "Artisans sur notre plateforme communautaire.",
      "solar-projects-enabled": "Projets solaires propulsés.",
      followers: "Followers",
    },
    team: {
      title: "L’équipe derrière la technologie",
      description:
        "We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.",
      people: {
        "person-1": {
          name: "Yann",
          role: "Operations lead & Co-founder",
          bio: "Yann Vermeil Schremmer continue d'imaginer de nouveaux modes de fonctionnement en interne, puis coordonne les divers interlocuteurs externes pour allier au mieux l’entreprenariat avec l’environnement.",
        },
        "person-2": {
          name: "Maarten",
          role: "CTO & Co-founder",
          bio: "Maarten Elgar développe la technologie qui aide les foyers à se mettre à l'énergie solaire en leur permettant d'obtenir des devis d'installateurs de confiance, puis de faire réaliser le reste du parcours en ligne.",
        },
        "person-3": {
          name: "Georges",
          role: "Sales lead & Co-founder",
          bio: "Georges Séraphim-André Nevsky aide les françaises et de français à découvrir leur potentiel solaire puis définir leur projet sur-mesure. Il aide les artisans photovoltaïques à bénéficier des nouvelles technologies de pointe.",
        },
        "person-4": {
          name: "Aurélie",
          role: "Head of administration",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
        "person-5": {
          name: "Kelly",
          role: "Head of Design & Junior Developer",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
        "person-6": {
          name: "Sebastian",
          role: "Front-end Engineer",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
      },
    },
    career: {
      title: "Projetsolaire is growing. Grow with us.",
      description:
        "We are based in Marseille and looking for people to join our passionate team. Interested in working in renewable energy and helping people go solar? Join us now",
      openings: {
        title: "Current open positions",
        "fullstack-engineer": "Senior fullstack engineer",
        "junior-frontend-engineer": "Junior frontend engineer",
        "junior-account-representative": "Junior account representative",
        "account-representative": "Senior account representative",
        "job-openings": "Job openings",
        role: "Role",
        description: "Description",
        salary: "Salary",
        location: "Location",
        "view-all-openings": "View all openings",
      },
      process: {
        "step-1": {
          title: "Apply",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-2": {
          title: "Screening",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-3": {
          title: "Interview",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-4": {
          title: "Job Offer",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
      },
      cta: {
        text: "Sounds like we are building something cool?",
        button: "Join us today",
      },

      apply: {
        title: "Apply for position",
        subtitle:
          "Tell us about you ! Complete the form below to submit your application.",
      },
    },
  },

  "dynamic-forms": {
    leads: {
      heading: {
        title: "Bonjour {fullName}!",
        subtitle:
          "Pour nous aider à modéliser le potentiel solaire de votre maison, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes !",
      },
      "verifying-token": "Vérification de votre accès",
      submitted: "Vous avez partagez vos informations avec succès !",
      steps: {
        "customer-information": {
          title: "Vos informations personnelles",
          subtitle: "Confirmer vos informations personnelles",
        },
        address: {
          title: "Votre adresse",
          subtitle: "Confirmer votre adresse",
        },
        "electricity-bill": {
          title: "Votre facture d électricité",
          subtitle: "Télécharger votre facture d'électricité",
          "paragraph-1":
            "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
        },
        "enedis-mandate": {
          title: "Vos données de consommation",
          subtitle:
            "Donnez nous l'autorisation de demander vos données de consommation à Enedis",
          "paragraph-1":
            "En cliquant sur le bouton «  Valider », le Mandant – l’internaute et utilisateur du présent site internet  –  autorise expressément le Mandataire  –  la société élance Technologies Propres S.A.S, domiciliée 31 Avenue de Ségur, 75007, Paris, immatriculée au registre de commerce de Paris sous le numéro 878 632 306 00014, au capital social de 10,000 €",
          "paragraph-2":
            "à demander et à recevoir communication auprès du gestionnaire du réseau électrique Enedis – SA à directoire et à conseil de surveillance, au capital de 270 037 000 euros, immatriculée au R.C.S. de Nanterre sous le numéro 444 608 442 et dont le siège social est situé Tour Enedis, 34 Place des Corolles, 92070 Paris La Défense Cedex –",
          "paragraph-3":
            "des données cochées ci-dessous, sous réserve de disponibilité :",
          "paragraph-4":
            "Usage des données : Réalisation de l’étude technico-financière pour l’installation d’un système photovoltaïque en autoconsommation électrique. Le Client accepte expressément que ses données personnelles soient conservées par le Mandataire à des fins de gestion et de traçabilité. La présente autorisation ne peut être cédée. Elle est consentie pour une durée indéterminée. Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès du Mandataire.",
          "checkbox-1": {
            title:
              "L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance);",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-2": {
            title:
              "L’historique des relevés d’index quotidiens, en kWh, du site;",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-3": {
            title:
              "L’historique la puissance maximale quotidienne, en kVA ou kWh, du site ;",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-4": {
            title:
              "L’historique de courbe de charge, aux pas restitués par Enedis, du site ;",
            description:
              "sur la période souhaitée, de 24 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-5": {
            title:
              "Les données techniques et contractuelles disponibles du site;",
            description:
              " Caractéristiques du raccordement, du dispositif de comptage et des informations contractuelles (option tarifaire, puissance souscrite…)",
          },
        },
        confirm: {
          title: "Envoyez vos informations",
          subtitle:
            "Une fois que vous êtes satisfait, soumettez vos informations pour votre modèle personnalisé",
          "paragraph-1":
            "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
        },
      },

      errors: {
        "token-invalid": "L'accès est invalide",
        "token-missing": "L'accès est invalide",
      },
    },
    administration: {
      heading: {
        title: "Bonjour {fullName}!",
        subtitle:
          "Pour compléter vos services, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes à remplir !",
        "subtitle-for-installer":
          "Pour compléter les services de {customerName} au {fullAddress}, nous avons besoin des informations suivantes. Cela ne vous prendra que qu'une seule minute à remplir !",
      },

      submitted: "Vous avez partagez vos informations avec succès !",
      "verifying-token": "Vérification de votre accès",
      steps: {
        "customer-information": {
          title: "Vos informations personnelles",
          subtitle: "Confirmer vos informations personnelles",
        },
        address: {
          title: "Votre adresse",
          subtitle: "Confirmer votre adresse",
        },
        documents: {
          title: "Documents",
          subtitle:
            "Télécharger le document demandé pour compéter votre services",
          "paragraph-1":
            "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
          "property-deed": "titre de propriété",
          "mayor-approval": "avis de non-opposition",
          "electricity-bill": "facture électrique",
          "consuel-approval": "AC du Consuel",
          "manual-mayor-single-mandate":
            "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
          "manual-enedis-single-mandate":
            "Signature du mandat spécial de représentation - Demande de raccordement",
          "wiring-diagram": "Schéma électrique unifilaire",

          esign: {
            "mayor-single-mandate":
              "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
            "enedis-single-mandate":
              "Signature du mandat spécial de représentation - Demande de raccordement",
            "dual-mandate": "Signature du mandat spécial de représentation",
          },
        },
        hardware: {
          title: "Matériel",
          subtitle: "Ajouter l'inventaire avec lequel le système est installé",
          panelLayoutDescription: "Description du calepinage",
          panel: "Panneaux",
          inverter: "Onduleur",
        },
        technicals: {
          title: "Technicals",
          subtitle: "Ajouter les informations techniques de l'installation",
          "paragraph-1":
            "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
          "system-size": "puissance de l’installation solaire (kVa)",
          "usage-point": "numéro du Point De Livraison (PDL)",
          "grid-connection-types": "monophasé ou triphasé ?",
          "monophase-grid-connection": "monophasé",
          "triphase-grid-connection": "triphasé",

          "meter-container-sizes": "largeur du coffret",
        },
        confirm: {
          title: "Envoyez vos informations",
          "paragraph-1":
            "Confirmez que vous êtes d'accord pour soumettre toutes les informations saisies. Nous commencerons alors à compléter vos services pour vous.</br>",
        },
      },

      errors: {
        "token-invalid": "L'accès est invalide",
        "token-missing": "L'accès est invalide",
      },
    },
  },

  support: {
    home: {
      title: "Get in touch with our team",
      subtitle:
        "Select a category to email the support team or contact us directly by using the form below.",
      "get-in-touch": "Prendre contact",
      "support-card": {
        "start-solar-autonomy": {
          name: "Démarrer autonomie solaire",
          description:
            "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        },
        "for-industry-farmers": {
          name: "Pour industriels & agriculteurs",
          description:
            "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe au service des installateurs photovoltaïques.",
        },
        "for-photovoltaic-professionals": {
          name: "Pour artisans photovoltaïques",
          description:
            "Besoin de précisions sur nos solutions informatiques ? Prenez contact avec notre équipe technico-administrative pour professionnels.",
        },
        "technical-support": {
          name: "Support technique",
          description:
            "Besoin d'aide sur dépannage technique sur votre compte ? Prenez contact avec notre équipe informatique.",
        },
        "solar-partnerships": {
          name: "Partenariats solaires",
          description:
            "Vous recherchez des solutions informatiques pour le domaine photovoltaïque ? Prenez contact pour des opportunitées de partenariat.",
        },
        press: {
          name: "Presse",
          description:
            "Besoin de nous joindre ? Prenez contact avec notre relation presse.",
        },
      },
    },
    "our-office": {
      title: "Come visit us at our office!",
      subtitle:
        "Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.",
    },
    "help-center": {
      title: "Explore our help centre for more information",
      subtitle:
        "We have alot of guides that may be useful in helping resolving your question",
    },
    schedule: {
      "chat-online": "Vous préférez discuter directement avec nous en ligne ?",
      "open-chatbox": "Ouvrir messagerie.",
      residential: {
        heading: "Pour particuliers",
        description:
          "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        breadcrumb: {
          name: "Démarrer son autonomie solaire",
        },
      },
      business: {
        heading: "Pour industriels & agriculteurs",
        description:
          "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe au service des installateurs photovoltaïques.",
        breadcrumb: {
          name: "Pour industriels & agriculteurs",
        },
      },
      installer: {
        heading: "Pour installateurs photovoltaïques",
        description:
          "Besoin de précisions sur nos solutions informatiques ? Prenez contact avec notre équipe technico-administrative pour professionnels.",
        breadcrumb: {
          name: "Pour installateurs photovoltaïques",
        },
      },
      "technical-support": {
        heading: "Support technique",
        description:
          "Besoin d'aide sur dépannage technique sur votre compte ? Prenez contact avec notre équipe informatique.",
        breadcrumb: {
          name: "Support technique",
        },
      },
      partnerships: {
        heading: "Partenariats solaires",
        description:
          "Vous recherchez des solutions informatiques pour le domaine photovoltaïque ? Prenez contact pour des opportunitées de partenariat.",
        breadcrumb: {
          name: "Partenariats solaires",
        },
      },
      press: {
        heading: "Presse",
        description:
          "Besoin de nous joindre ? Prenez contact avec notre relation presse.",
        breadcrumb: {
          name: "Presse",
        },
      },
    },
  },
  help: {
    header: {
      title: "Help Centre",
    },
    home: {
      search: {
        title: "Hi! How can we help?",
        subtitle:
          "Start building for free, then add a site plan to go live. Account plans unlock additional features.",
        placeholder: "Search guides",
      },
      householder: "I am a household",
      installer: "I am an installer",
    },
    householder: {
      title: "Tous les sujets",
      subtitle: "Parcourez notre bibliothèque complète de rubriques d'aide.",
      guides: {
        "your-internet-portal": {
          title: "Votre portail internet",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace en ligne.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir pleinement son gain économique, comparer les prix euro-watts-crête, régler l’acompte et le solde, et télécharger les factures et reçus.",
        },
        "choose-your-solar-system": {
          title: "Choisir son système solaire",
          description:
            "Comment bénéficier d’une étude de votre pourcentage d’autonomie électrique, et donc de vos économies, par rapport à différentes alternatives de votre projet solaire.",
        },
        "choosing-a-customised-quote": {
          title: "Choisir son devis sur-mesure",
          description:
            "Comment sélectionner parmi plusieurs propositions complètes, détaillés et sur-mesure d’installateurs photovoltaïques proches de chez vous.",
        },
        "validate-feasibility": {
          title: "Valider la faisabilité",
          description:
            "Comment planifier puis se préparer pour la rencontre avec le technicien, et se faire réaliser la faisabilité technique  chez vous du cahier des charges.",
        },
        "investing-in-solar": {
          title: "Investir dans le solaire",
          description:
            "Comment contrôler les assurances et certifications du maitre d’œuvre, signer électroniquement son devis définitif, et régler l’acompte en toute sécurité.",
        },
        "carrying-out-administrative-procedures": {
          title: "Réalisation des démarches administratives",
          description:
            "Comment suivre l’obtention de l’autorisation de l’urbanisme auprès de la mairie, puis planifier l’installation photovoltaïque.",
        },
        "installation-and-commissioning": {
          title: "Pose & mise en service",
          description:
            "Comment valoriser l’injection du surplus avec un raccordement au réseau électrique respectant les normes en vigueur.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales.",
        },
      },
      cta: {
        title: "Discover others solar journey",
        subtitle:
          "Quasi est quaerat. Sit molestiae et. Provident ad dolorem occaecati eos iste. Soluta rerum quidem minus ut molestiae velit error quod. Excepturi quidem expedita molestias quas.",
        description:
          "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat. Quasi aperiam sit non sit neque reprehenderit.",
        button: "Join our team",
      },
    },
    installer: {
      title: "Tous les sujets",
      subtitle: "Tous les sujets",
      guides: {
        "your-online-account": {
          title: "Votre compte en ligne",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace professionnel.",
        },
        "your-solar-projects": {
          title: "Vos projets solaires",
          description:
            "Instructions simples à suivre pour la gestion et le suivi de prospects, devis, dossiers puis chantiers photovoltaïques sur une seule interface en ligne.",
        },
        "online-photovoltaic-studies": {
          title: "Études photovoltaïques en ligne",
          description:
            "Tout savoir sur comment mieux vendre avec nos présentations personnalisables, vos propositions sur-mesure de systèmes solaires, et notre méthode technico-financière.",
        },
        "dematerialised-administrative-management": {
          title: "Gestion administrative automatisée",
          description:
            "Tout savoir sur comment gagner du temps avec nos services numériques d’envoi et de suivi des démarches administratives obligatoires pour installations photovoltaïques.",
        },
        "contact-and-quotation-proposals": {
          title: "Discover new clients",
          description:
            "Tout savoir sur les signatures électroniques, les paiements automatiques sur internet, la mise en conformité, le service d’assistance de maîtrise d’ouvrage, et bien plus encore. ",
        },
        "materials-planning": {
          title: "Planification du matériel",
          description:
            "Tout savoir sur les listes de matériel de l’entreprise, kits personnalisables, approvisionnement et gestion du stock, comptes rendus de chantiers, et bien plus encore.",
        },
        "after-sales-service": {
          title: "Service après-installation",
          description:
            "Tout savoir sur la responsabilité de chacun, communication entre le maitre d’ouvrage et le maitre d’œuvre, l’entretien, le suivi de rendements, le dépannage et le nettoyage.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir de l’argent, gagner du chiffre d’affaires, acheter au travers de la boutique en ligne, et télécharger les factures comptables.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales mentions légales.",
        },
      },
      cta: {
        title: "Join our platform",
        description:
          "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
        benefit: {
          benefit1: "Competitive salaries",
          benefit2: "Flexible work hours",
          benefit3: "30 days of paid vacation",
          benefit4: "Annual team retreats",
          benefit5: "Benefits for you and your family",
          benefit6: "A great work environment",
        },
        button: "Sign up now",
      },
    },
    "side-bar": {
      "bread-crumb": {
        help: "Help",
      },
      search: "Search",
      "for-anything": "for anything",
      placeholder: "...",
    },
  },

  common: {
    loading: "Loading...",
    processing: "Chargement...",
    warning: "Alerte !",
    copyright: "2023 © projetsolaire.com. All rights reserved.",
    name: "Name",
    "enter-your-name": "Enter your name",
    email: "Email",
    "enter-your-email": "Enter your email address",
    "your-email": "Your email",
    password: "Password",
    "enter-your-password": "Enter your password",
    siret: "Votre numéro SIRET",
    "enter-your-siret": "Entrez votre SIRET",
    "phone-number": "Votre numéro de téléphone",
    "enter-your-phone-number": "Entrez votre numéro de téléphone",
    "sign-up": "Sign Up",
    "sign-in": "Sign in",
    or: "Ou",
    apply: "Apply",
    optional: "Optional",
    "select-a-tab": "Select a tab",
    title: {
      mr: "Mr",
      mrs: "Mrs",
    },
    "not-signed-in": {
      title: "Not logged in",
      description: "Sorry, to access this content you need to be logged in",
      button: "Sign in now",
    },
    "cookie-gdpr": {
      "cookie-text-1":
        "Nous utilisons les cookies pour mettre nos services à votre disposition.",
      "cookie-text-2": "Pour en savoir, veuillez explorer notre ",
      "cookie-link": "Déclaration de Confidentialité",
      accept: "Accepter",
      decline: "Refuser",
    },
    "algolia-search-dialog": {
      search: "Search",
      placeholder: "Search Anything...",
      results: "Results",
      tags: {
        installer: "Installer",
        householder: "Householder",
        "help-center": "Help center",
        blog: "Blog",
      },
    },
    verifying: {
      title: "Verifying",
      title2: "Verifying that its you",
      description: "We are verifying your access, please bear with us",
    },
    error: {
      title: "Oops there was a problem",
      button: "Go back home",
    },
    success: {
      "confirmed-account": "Successfully confirmed your account",
      "sign-in": "Sign in now",
      "reset-password": "Successfully reset password",
      continue: "Continue",
    },
    "page-not-found": {
      title: "This page does not exist.",
      description: "The page you are looking for could not be found.",
      "go-home": "Go back home",
    },
    "newsletter-dialog": {
      title: "Signup to our newsletter",
      description:
        "We send out some new content each month updating you on the latest news with our mission",
      form: {
        "first-name": "First name",
        "last-name": "Last name",
        company: "Company",
        email: "Email",
      },
      "agree-to-policies": "Agree to policies",
      "switch-agree": "By selecting this, you agree to our",
      "privacy-policy": "privacy policy",
      subscribe: "Subscribe",
    },
    month: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    pagination: {
      showing: "Affiche",
      to: "à",
      of: "de",
      results: "résultats",
      previous: "Précédent",
      next: "Suivant",
      related: "Related",
    },
  },

  snackbars: {
    "lead-submitted": "Nous avons reçu votre demande",
    "password-reset-success": "votre mot de passe a été mis à jour.",
    "reset-token-sent":
      "Vérifiez votre boîte mail pour mettre à jour votre mot de passe.",
    "register-success":
      "Vous avez créé votre compte projetsolaire ! Confirmez votre inscription avec le courriel dans votre boîte mail.",
    "login-success": "Bienvenue sur projetsolaire",
    errors: {
      common:
        "Une erreur s’est produite. Veuillez réessayer puis nous contacter si le problème persiste.",
      "password-reset-failed": "La mise à jour du mot de passe a échouée.",
      "login-failed":
        "Connexion échouée : vérifier votre saisie puis réessayer.",
      "register-failed":
        "Nous n’avons pas pu créer votre compte. Veuillez réessayer puis nous contacter si le problème persiste.",
    },
  },
}
